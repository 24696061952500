import { FunctionComponent, useEffect, useState } from "react";
import Snackbar from '@material-ui/core/Snackbar';
import { Alert } from "@material-ui/lab";
import config from '../../config';
import axios from 'axios';
import { useTranslation } from "react-i18next";

const NetworkCheck: FunctionComponent = () => {

  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const timeout = setTimeout(() => {
      source.cancel();
      setOpen(true);
      // Timeout Logic
    }, 10000);
    
    axios.get(`${config.COCKPIT_API_URL}${config.COCKPIT_API_URL_PREFIX}/network-check`, {cancelToken: source.token}).then((_result) => {
      clearTimeout(timeout);
      setOpen(false)
    });
  }, [])

  return <>
    <Snackbar open={open} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={handleClose}>
      <Alert onClose={handleClose} severity="error">
        {t('alert.networkCheck.noAuthorization')}
      </Alert>
    </Snackbar>
  </>
}

export default NetworkCheck;